import React, { useState, useEffect, useMemo } from "react";
import './App.css';
import Navbar from './components/Navbar.js'
// import './components/Navbar.css'
import Intro from './components/Intro.js'
// import './components/Intro.css'
import About from './components/About.js'
// import './components/About.css'
import Facts from './components/Facts.js'
// import './components/Facts.css'
import Skills from './components/Skills.js'
// import './components/Skills.css'
import Resume from './components/Resume.js'
// import './components/Resume.css'
import PersonalProjects from './components/PersonalProjects.js'
// import './components/Portfolio.css'
import './components/PortfolioDetails.css'
import Services from "./components/Services";
// import './components/Services.css'
import Testimonials from "./components/Testimonials";
// import './components/Testimonials.css'
import Contact from "./components/Contact";
// import './components/Contact.css'
import Footer from "./components/Footer";
// import './components/Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { BiAward, BiBookContent, BiEnvelope, BiFileBlank, BiHeadphone, BiHome, BiJoystick, BiLogoFacebook, BiLogoInstagram, BiLogoLinkedin, BiLogoSkype, BiLogoTwitter, BiServer, BiSmile, BiUser } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";
import PureCounter from "@srexi/purecounterjs";
import { BiUpArrowAlt } from "react-icons/bi";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const pure = new PureCounter();

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  // function useIsInViewport(ref) {
  //   const [isIntersecting, setIsIntersecting] = useState(false);
  
  //   const observer = useMemo(
  //     () =>
  //       new IntersectionObserver(([entry]) =>
  //         setIsIntersecting(entry.isIntersecting),
  //       ),
  //     [],
  //   );
  
  //   useEffect(() => {
  //     observer.observe(ref.current);
  
  //     return () => {
  //       observer.disconnect();
  //     };
  //   }, [ref, observer]);
  
  //   return isIntersecting;
  // }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos,
      behavior: 'smooth'
    })
  }

  return (
    <div className="App">
      <div className="main">
        {/* <i class="bi bi-list mobile-nav-toggle d-lg-none"></i> */}

        <Navbar 
          select={select} 
          on={on} 
          onscroll={onscroll} 
          scrollto={scrollto} 
          // useIsInViewport={useIsInViewport}
        />
        <Intro/>

        <div className="mainContent">
          {/* About Section */}
          <About/>

          {/* Facts/Awards Section */}
          {/* <Facts/> */}

          {/* Skills */}
          {/* <Skills select={select}/> */}

          {/* Resume */}
          <Resume/>

          {/* Portfolio */}
          {/* COULD change this to a the current grid with some slide in animation, then use a modal to pop it open */}
          {/* <PersonalProjects select={select} AOS={AOS} on={on}/> */}

          {/* Services */}
          <Services/>

          {/* Testimonials */}
          {/* <Testimonials/> */}

          {/* Contact */}
          <Contact/>
        </div>
      </div>


      {/* Footer */}
      <Footer/>
      <a href="#hero" class="back-to-top d-flex scrollto"><i class="bi bi-arrow-up-short"><BiUpArrowAlt/></i></a>
    </div>
  );
}

export default App;
