import React from 'react';
import './Services.css';
import { ProjectsData } from './ProjectsData';
// import { BiArch, BiFile, BiLayer, BiLogoDribbble, BiSlideshow, BiTachometer } from 'react-icons/bi';

const Services = (props) => {
  const first_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch";
  const second_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0";
  const third_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0";
  const fourth_box_classname = "col-lg-4 col-md-6 d-flex align-items-stretch mt-4";

  const getItemClassName = (index) => {
    if (index === 0) {
      return first_box_classname;
    } else if (index === 1) {
      return second_box_classname;
    } else if (index === 2) {
      return third_box_classname;
    } 
    return fourth_box_classname;
  }

  const getAOSDelay = (index) => {
    if (index%3 === 0) {
      return "100";
    } else if (index%3 === 1) {
      return "200";
    }
    return "300";
  }

  return (
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Projects</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>

        <div class="row">
          {ProjectsData.map((item, index) => {
            return (
              <div key={index} className={getItemClassName(index)} data-aos="zoom-in" data-aos-delay={getAOSDelay(index)}>
                <div class={`icon-box iconbox-${item.colour}`}>
                  <div class="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path className="orange_blob" stroke="none" strokeWidth="0" fill="#f5f5f5" d={item.path_d}></path>
                    </svg>
                    {/* {item.icon} */}
                  </div>
                  <h4><a href={item.link}> {item.title} </a></h4>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </section>
  );
}

export default Services;
