import React, { useState, useRef } from 'react';
import './Contact.css';
import { BiEnvelope, BiGlobeAlt, BiPhone } from 'react-icons/bi';
import emailjs from '@emailjs/browser';

const Contact = (props) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    console.log(`EMAILJS_SERVICE_ID: ${process.env.REACT_APP_EMAILJS_SERVICE_ID}, EMAILJS_TEMPLATE_ID: ${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}, PUBLIC_EMAILJS_KEY: ${process.env.REACT_APP_PUBLIC_EMAILJS_KEY}`)
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_EMAILJS_KEY)
      .then((result) => {
          setSuccessMessage("Your message has been sent successfully! I have sent a copy of your message to your email :)")
          console.log(result.text);
      }, (error) => {
          setErrorMessage("Sorry, it looks like your message was not sent successfully. Please contact me at tjin368@gmail.com")
          console.log(error.text);
      });
  };

  // const handleSubmit = (e) => {
  //   console.log(`Name: ${name}, Email: ${email}, Subject: ${subject}, Message: ${message}`)

  //   e.preventDefault();

  //   // Create a FormData object to send data to the PHP script
  //   const formData = new FormData();
  //   formData.append('name', name);
  //   formData.append('email', email);
  //   formData.append('subject', subject);
  //   formData.append('message', message);

  //   fetch('../../../backend/Contact.php', {
  //     method: 'POST',
  //     body: formData,
  //   })
  //   .then((response) => response.text())
  //   .then((data) => {
  //     if (data === 'success') {
  //       setSuccess('Your message has been sent successfully!')
  //       setError("")
  //     } else {
  //       setSuccess("")
  //       setError("Oops! Something went wrong, and we couldn\'t send your message.")
  //     }
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //     setError("An error occurred while sending your message.");
  //   });
  // };

  return (
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
        </div>

        <p className='contactText'>Let's get in touch! Please reach out to me through LinkedIn or via email.</p>

        <div class="row mt-1">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"><BiGlobeAlt/></i>
                <h4>Location:</h4>
                <p>Toronto, ON, Canada</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"><BiEnvelope/></i>
                <h4>Email:</h4>
                <p>tjin368@gmail.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"><BiPhone/></i>
                <h4>Call:</h4>
                <p>+1 905 269 8025</p>
              </div>
            </div>
          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">
            <form ref={form} onSubmit={sendEmail} class="php-email-form">
            {/* <form onSubmit={handleSubmit} class="php-email-form"> */}
            {/* <form action="forms/contact.php" method="post" class="php-email-form"> */}
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" class="form-control" name="from_name" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="from_email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="user_subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                  {successMessage && (
                    <div className="sent-message">{successMessage}</div>
                  )}

                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                {/* <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div> */}
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>
  );
}

export default Contact;
