import React from 'react';
import './About.css';
import ProfileImage from '../img/Tristan_Photo.png';
import { BiChevronRight } from 'react-icons/bi';
import { ReactComponent as ReadingSVG } from './SVGImage.svg';
import { ReactComponent as ProgrammingSVG } from './programming-animate.svg';

const About = (props) => {
  return (
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>About</h2>
          <p> Based in Toronto, I'm currently studying my fouth year of software engineering and business at Western University. 
            As a software developer, I hope to deliver unique products to make individual lives easier. 
            Currently, I'm seeking a Summer 2024 internship where I can continue to deliver this passion towards real-world experiences.
          </p>
          {/* <p>Outside of work, you can find me playing tennis, running, or exploring new restaurants in town.</p> */}
        </div>

        <div class="row">
          <div class="col-lg-4">
            {/* <img src={ProfileImage} class="img-fluid" alt=""/> */}
            {/* {document.addEventListener('DOMContentLoaded', function () {window.setTimeout(document.querySelector('svg').classList.add('animated'),1000);})} */}
            {/* <ReadingSVG/> */}
            <ProgrammingSVG data-aos="fade-up"/>
          </div>
          <div class="col-lg-8 pt-4 pt-lg-0 content">
            <h3>Student @ Western University</h3>
            {/* <p class="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p> */}
            <div class="row">
              <div class="col-lg-6">
                <ul>
                  {/* <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Birthday:</strong> <span>4 Dec 2002</span></li> */}
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>LinkedIn:</strong> <span><a href="https://www.linkedin.com/in/tristan-jin/">linkedin.com/in/tristan-jin/</a></span></li>
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Phone:</strong> <span>+1 (905) 269-8025</span></li>
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>City:</strong> <span>Toronto, ON, Canada</span></li>
                </ul>
              </div>
              <div class="col-lg-6">
                <ul>
                  {/* <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Age:</strong> <span>20</span></li> */}
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Degree:</strong> <span>BESc &amp; HBA</span></li>
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Email:</strong> <span>tjin368@gmail.com</span></li>
                  <li><i class="bi bi-chevron-right"> <BiChevronRight/> </i> <strong>Work Status:</strong> <span>Available!</span></li>
                </ul>
              </div>
            </div>
            {/* <p>
              Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
              Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
            </p> */}
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;
