import React from 'react';
import './Resume.css';

const Resume = (props) => {
  return (
    <section id="resume" class="resume">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Resume</h2>
        </div>

        <div class="row">
          {/* <div class="col-lg-6">
            <h3 class="resume-title">Summtary</h3>
            <div class="resume-item pb-0">
              <h4>Tristan Jin</h4>
              <p><em>*********************************INSERT SOMETHING HERE*********************************</em></p>
              {/* <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and developing user-centered digital/print marketing material from initial concept to final, polished deliverable.</em></p> *}
              <ul>
                <li>+1 (905) 269-8025</li>
                <li>tjin368@gmail.com</li>
              </ul>
            </div>

            <h3 class="resume-title">Education</h3>
            <div class="resume-item">
              <h4>Bachelor of Engineering Science, Software Engineering</h4>
              <h5>Sept 2020 - May 2025</h5>
              <p><em>Western University, London, ON, Canada</em></p>
              <p>Relevant Courses: Data Structures & Algorithms, Software Design, Scripting, Operating Systems, Statistics</p>
            </div>
            <div class="resume-item">
              <h4>Honours Business Administration</h4>
              <h5>Sept 2020 - May 2025</h5>
              <p><em>Ivey Business School at Western University, London, ON, Canada</em></p>
              <p>Relevant Courses: Leveraging Information &amp; Technology, Operations, Decision Making with Analytics, Leading People &amp; Organizations, Finance, Accounting, Strategy, Marketing</p>
            </div>
          </div> */}
          <div class="resume-column col-lg-10">
            <h3 class="resume-title">Professional Experience</h3>
            <div class="resume-item">
              <h4>Software Engineering Intern, Low Power Embedded Artificial Intelligence</h4>
              <h5>May 2023 - Aug 2023</h5>
              <p><em>Qualcomm, Markham, ON, Canada</em></p>
              <ul>              
                <li>Implemented an individual project to detect hardware inefficiencies by generating and profiling low-level events; Presented 3 key findings to 70 members, increasing knowledge of various optimizations.</li>
                <li>Deployed an internal tool used during pre-silicon to capture configuration field mismatches within binary files, successfully resolving 20+ field discrepancies across 950 test cases.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Machine Learning Systems</h4>
              <h5>May 2022 - Sept 2022</h5>
              <p><em>Qualcomm, Markham, ON, Canada</em></p>
              <ul>
                <li>Developed a supervised learning algorithm from scratch to explore machine learning training optimization for embedded systems, achieving 98.6% post-quantization inferencing accuracy. </li>
                <li>Presented computer-vision based algorithms to 2 VPs regarding incoming machine learning regulations, resulting in an increased annual investment of $500,000+ to understand deep learning model predictions. </li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Software Engineering Intern, Frontend Development</h4>
              <h5>Mar 2021 - Aug 2021</h5>
              <p><em>Tauria, Waterloo, ON, Canada</em></p>
              <ul>
                <li>Independently engineered a data-stream organizing service to production, granting clients the ability to reduce their CPU consumption by at least 15% through a custom range selector and data stream filtration.</li>
                <li>Spearheaded inter-team communication by leading 10 weekly sprints, prioritizing the client’s need for our military grade end-to-end edge encryption video conferencing platform.</li>
                <li>Collaborated in a startup environment with partners from South Korea, Brazil, and the United States to optimize frontend architecture; Improving UX by 20% through the single-responsibility principle.</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Resume;
